@font-face{
    font-family: 'PingFang';
    src: url('/asset/fonts/PingFangTC.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: 'PingFang Bold';
    src: url('/asset/fonts/PingFang Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face{
    font-family: 'PingFang Medium';
    src: url('/asset/fonts/PingFang Medium.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face{
    font-family: 'Lato';
    src: url('/asset/fonts/Lato-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family: 'Lato Bold';
    src: url('/asset/fonts/Lato-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face{
    font-family: 'Lato Medium';
    src: url('/asset/fonts/Lato-Medium.ttf');
    font-weight: bold;
    font-style: normal;
}

