/********
information
*********/
.top ,.left ,.right{
  position: relative;
  top:0;
}
.top{
  width: 100%;
  height: 70vh;
  background-image: url('../../../asset/img/img-product.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 2;
  position: relative;
}
.top-bg{
  display: none;
}

.watergtopimg{
  width: 100%;
  height: 35vh;
  background-image: url('../../../asset/img/img-product-l.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.glasstopimg{
  width: 100%;
  height: 35vh;
  background-image: url('../../../asset/img/img-product-r.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.leftlink ,.rightlink{
  display: inline-block;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, .1);
}
.left{
  /*width: 50%;*/
  height: 30vh;
  background-image: url('../../../asset/img/img-product-l.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;


}
.right{
  /*width: 50%;*/
  height: 30vh;
  background-image: url('../../../asset/img/img-product-r.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
/*  -webkit-transition: 1s;*/
}
.col-sm-12 ,.col-sm-6{
  padding: 0;
}
.toptitle{
  position: absolute;
  left: 60px;
  z-index: 1;
  top: 48%;
}
.headline{
  height: 32px;
  font-family: "PingFang Medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 3px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.7);
  z-index: 2;
  }

.toptext{
  width: 540px;
  height: 22px;
  font-family: "PingFang";
  font-size: 14px;
  line-height: 1.57;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.7);
}

.left:hover,.right:hover{

 opacity:0.7;
}




.righttext, .lefttext{
  font-family: "PingFang Medium";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 3px;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: absolute;
  left: 0;
  top:50%;
  text-align: center;
  width: 100%;
 }

.righttext .underline, .lefttext .underline{
  color: white;
  font-size: 10px;
  letter-spacing: 0.7px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid white;
  padding-bottom: 3px;
  width: 58px;
  margin: 0 auto;
  line-height: 13px;
}

/*******
glasspinfo,waterginfo
*******/

.glassptoptext{
  font-family: "PingFang Medium";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 6px;
  color: #ffffff ;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.2);
}

.unselectedinfo{
  width: 50%;
  height: 45px;
  background-color: white;
  /*box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);*/
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  float: left;
  line-height: 45px;
  border-bottom: 1px solid rgba(205, 205, 205, 0.4);
}
.unselectedinfo:hover{
  border-bottom: 3px solid rgba(211, 16, 16, 0.4);
}
.selectedinfo{
  width: 50%;
  height: 45px;
  background-color: white;
  /*box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);*/
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: #d31010;
  text-align: center;
  float: left;
  line-height: 45px;
  /*box-shadow: inset 0 0 10px #000000;*/
  border-bottom: 3px solid #d31010;
}
.glassptext{
  font-family: "PingFang Medium";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  color: #4a4a4a;
  margin-top: 45px;
  margin-left: 60px;
}

.glassptext .en{
  font-family: 'Amiri';
  font-size: 21px;
}
.waterg{
  position: absolute;
  left: 60px;
  margin-right: 30px;
  word-break: break-all;
  margin-top: 45px;
  margin-bottom: 50px;
}
.infotext{
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  color: #4a4a4a;
  margin-bottom: 45px;

}
.watergtable{
  margin-left: 20px;
  margin-bottom: 30px;
}
.infotexttitle{
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: #d31010;
  margin: 15px 0;

}
/*.infotext{
  font-family: "PingFang";
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  color: #4a4a4a;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 30px;
}*/
.leftinfotext{
  font-family: "PingFang Medium";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  text-align: left;
  color: #000000;
  margin-left: 20px;
  padding-bottom: 15px;
  vertical-align:top;
  min-width: 42px;
}
.rightinfotext{
  font-family: "PingFang";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  text-align: left;
  color: #4a4a4a;
  padding-left: 30px;
  padding-bottom: 15px;
  vertical-align: top;
  min-width: 75px;
  }

.en-visibility {
  display: none;
}
/*.infotext2{
  font-family: "PingFang";
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  color: #4a4a4a;
  margin-left: 20px;
  padding-bottom: 80px;
}*/

@media all and (max-width: 500px){
  .mobile-fix-header{
    position: fixed;
    width: 100vw;
    z-index: 10;
    top: 0;
  }
  .mobile-fix-header .watergtopimg{
    height: 240px;
  }
  .mobile-fix-header .watergtopimg.open{
    height: 140px;
  }
  .glasstopimg{
    height: 240px;
  }
  .glasstopimg.open{
    height: 140px;
  }
  .top{
    height: 300px;
  }
  .top-bg{
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    width: 100vw;
    height: 125px;
  }
  .toptitle{
    width: 100%;
    left: 0;
    padding: 20px;
    top: 55%;
  }
  .headline{
    height: 20px;
    font-size: 14px;
    width: 100%;
  }
  .toptext{
    width: 100%;
  }
  .righttext, .lefttext{
    color: white;
    font-size: 16px;
    letter-spacing: 1.1px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    top: 35%;
  }

  .selectedinfo, .unselectedinfo{
    width: 50%;
  }
  .waterg{
    position: relative;
    left: 0;
    padding: 275px 20px 20px;
    margin-right: 0px;
  }
  .waterg.open{
    padding: 175px 20px 20px;
  }
  .infotexttitle{
    text-align: center;
  }
  .infotext{
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #4a4a4a;
  }
  .info-wrap{
    padding: 10px;
    background-color: rgba(216, 216, 216, 0.1);
    min-height: 5px;
    overflow: hidden;
    margin-bottom: 5px;
    margin-left: -10px;
    width: calc( 100vw - 20px );
  }
  .infotext .title{
    font-size: 14px;
    line-height: 1.79;
    letter-spacing: 1.6px;
    color: #d31010;
    margin-bottom: 3px;
    font-weight: bold;
  }
  .glassptoptext{
    padding-left: 20px;
    text-align: left;
    line-height: 300px !important;
  }
  .glassptoptext.open{
    line-height: 200px !important;
  }
  .glassptext{
    height: 200px;
    margin-left: 20px;
    padding-top: 305px;
    padding-bottom: 200px;
  }
  .glassptext.open{
    padding-top: 205px;
  }
  .glassptext .ch{
    font-size: 14px;
    margin-top: 15px;
  }
}
