
body{
    margin: 0;
    overflow-x: hidden;
}
a,a:link,a:hover ,a:visited,a:active,a:focus{
    text-decoration: none;
    color: white;
}
.mobile-show{
  display: none;
}

.animation{
  transition: all 0.3s linear;
}
.unselected:hover:before {
    content: url(/asset/img/act.png); /* no need for qoutes */
    display: inline-block;
    opacity: .7;
}
.liststyle{
    position: relative;
    list-style-type: none;
    width: 140px;
    height: 30px;
    background-color: #000000;
}
.leftside {
  position: fixed;
  top: 0;
  left: 0;
  width: 230px;
  height: 100%;
  background-color: #000000;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.5);
}
.nav_logo {
  width: 170px;
  height: 110px;
  object-fit: contain;
  position: absolute;
  top:59px;
  left: 30px;
}
.navbar{
  position: absolute;
  margin-top: 44px;
  left: 10px;
  top: 185px;
}
.selected{
  width: 26.7px;
  height: 16px;
  font-family: "PingFang Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: left;
  color: #ffffff;
}

.selected:before {
    position: absolute;
    top: 4px;
    left: -20px;
    content: url(/asset/img/act.png);
    display: inline-block;
}

.unselected:before {
    position: absolute;
    top: 4px;
    left: -20px;
    content: url(/asset/img/act.png);
    opacity: 0;
    display: inline-block;
}

.unselected {
  width: 25px;
  height: 16px;
  opacity: 0.6;
  font-family: "PingFang";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: left;
  color: #ffffff;
}

.unselected:hover:before{
  content: url(/asset/img/act.png);
}

.leftsidebottom{
  position: absolute;
  bottom: 40px;
  width: 100%;
  font-size: 12px;
}

.botton{
  border: solid 1px #fff;
  width: 120px;
  height: 35px;
  padding-top: 8px;
  text-align: center;
  border-radius: 2px;
  display: inline-block;
  font-family: "PingFang";
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 16px;
  margin-right: 10px;
  background-color: rgba(36, 36, 36, 0.6);
}

.botton:hover{
background-color: rgba(36, 36, 36, 0.86);
color: #ffffff;
}

.companyinfo {
  position: relative;
  width: 188px;
  /*height: 80px;*/
  opacity: 0.5;
  font-family: "Lato";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  text-align: left;
  color: #ffffff;
  margin-left: 30px;
  margin-bottom: 15px;

}
.language{
  position: relative;
  /*width: 260px;*/
  margin-left: 30px;
  margin-bottom: 22px;
  text-decoration:none;
  /*display: inline-block;*/
}
.Chinese {
  width: 40px;
  height: 21px;
  font-family: "PingFang";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  color: #ffffff;
  opacity: 0.6;
  margin-right: 11px;
}
.English{
width: 32px;
  height: 21px;
  font-family: "Lato";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 11px;
  margin-right: 11px;
}
.Japanese{
  width: 40px;
  height: 21px;
  font-family: "PingFang";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.1;
  color: #ffffff;
  opacity: 0.6;
  margin-left: 11px;
  margin-right: 11px;
}
.clicked{
  opacity: 1;
}

.icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px;
  color: white;
}

.Copyright-FORMOSA {
  position: relative;
  opacity: 0.5;
  font-family: "Lato";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: #ffffff;
  margin-left: 30px;
}

.rightside{
  position: relative;
  margin-left: 230px;
}
.homerightside{
  width: 100vw;
  height: 100vh;
  background-image: url('../../../asset/img/img-home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.mobile-info-title{
  font-size: 14px;
  display: block;
  /*margin-left: 3px;*/
  line-height: 1.5;
}
/*
indexpage
*/

/*.background_video {
    width: auto;
    height: 100%;
    position: relative;
    object-fit: cover;
    opacity: 0.85;
    z-index: 0;
    margin-top: 0;
    margin-left: 0;
}*/
.rightsidetext{
  position: absolute;
  top: 40%;
  left: 60px;
}

.Formosan-Glass-Che {
  width: 442px;
  height: 78px;
  font-family: 'Amiri', serif;
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 3px;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.7);
  }

.P2{
  color: #313131;
  /*height: 48px;*/
  opacity: 0.9;
  font-family: "PingFang";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.7);
}



@media all and (max-width: 500px){
  .mobile-show{
    display: block;
  }
  .mobile-not-show{
    display: none;
  }
  .rightside{
    margin-left: 0;
  }
  .leftside{
    position: relative;
    padding: 20px 25px;
    width: 100vw;
  }
  .mobile-nav{
    width: 100vw;
    height: 50px;
    background-color: black;
    opacity: 0.85;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .mobile-nav img{
    position: fixed;
    top: 6px;
    left: 13px;
    width: 137px;
  }
  .mobile-nav .nav-list{
    width: 100vw;
    height: 255px;
    position: fixed;
    top: 50px;
    left: 0px;
    background-color: black;
    display: none;
  }
  .mobile-nav .nav-list.show{
    display: block
  }
  .mobile-nav .nav-list .navbar{
    position: relative;
    margin-top: 0;
    left: 0;
    top: 0;
    width: 170px;
    margin: 30px auto;
    text-align: center;
    padding: 0;
  }
  .mobile-nav .nav-list .navbar li{
    font-size: 14px;
    height: 45px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    padding: 10px;
  }
  .unselected{
    opacity: 1;
  }
  .liststyle{
    width: 170px;
  }
  .mobile-nav .language{
    width: 260px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
  }
  .burger-wrap{
    width: 50px;
    height: 50px;
    background-color: #d31010;
    position: fixed;
    top: 0;
    right: 0;
  }
  .burger{
    width: 24px;
    height: 22px;
    position: fixed;
    right: 13px;
    top: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 11;
  }

  .burger span{
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
/*  .burger span:nth-child(1){
    top: 0px;
  }

  .burger span:nth-child(2){
    top: 8px;
  }

  .burger span:nth-child(3){
    top: 16px;
  }
  .burger.open span:nth-child(1){
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .burger.open span:nth-child(2){
    opacity: 0;
    left: -60px;
  }
  .burger.open span:nth-child(3){
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }*/
  #burger span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #burger span:nth-child(2) {
    top: 8px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #burger span:nth-child(3) {
    top: 16px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #burger.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 3px;
  }

  #burger.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #burger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 17px;
    left: 3px;
  }


  .selected:before{
    top: 13px;
    left: 30px;
  }
  .unselected:before{
    top: 13px;
    left: 30px;
  }
  .Japanese{
    margin-right: 0;
  }
  .leftsidebottom{
    position: relative;
    bottom: 0px;
  }
  .companyinfo{
    margin-left: 0px;
    width: 100%;
  }
  .Copyright-FORMOSA{
      width: 100%;
      margin-left: 0px;
  }
  .rightsidetext{
    left: 0;
    width: 100vw;
    padding: 20px;
  }
  .rightsidetext .Formosan-Glass-Che{
    width: 100%;
    font-size: 23px;
    /*height: 56px;*/
  }
  .rightsidetext .P2{
    width: 100%;
    font-size: 14px
  }
  .rightsidetext .botton{
    width: 45%;
    margin: 16px 2%;
    height: 45px;
    padding-top: 11px;
  }
  .companyinfo{
    min-height: 10px;
    overflow: hidden;
    height: auto;
    opacity: 1;
    line-height: 20px;
  }

  .border-box{
    display: black;
    width: 100%;
    height: 55px;
    border: solid 1px #979797;
    border-radius: 2px;
    margin: 7px auto;
    padding: 16px;
    text-align: left;
  }
  .mobile-address {
    padding: 7px 16px;
  }
  .Copyright-FORMOSA{
    margin-top: 0;
    text-align: center;
  }
  .Formosan-f-Che{
    letter-spacing: 0.6px;
  }
  .Chinese {
    font-size: 14px;
  }
  .English{
    font-size: 14px;
  }
  .Japanese{
    font-size: 14px;
  }
}
