/********
about
********/
.about_righttop , .glasspinfo{
  position: relative;
}
.about_righttop{
  width: 100%;
  height: 35vh;
  background-image: url('../../../asset/img/img-about.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.abouttitle {
  height: 100px;
  line-height:35vh;
  text-align: center;
  font-family: "PingFang Medium";
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 6px;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  position: absolute;

}

.glassptopimg{
  position: relative;
  height: 100%;
}

.abouttitle, .glassptoptext{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding-left: 60px;
}
.abouttable{
  margin-left: 80px;
  }

.aboutcompany{
  position: absolute;
  margin-top: 45px;
  padding-bottom: 80px;
  margin-right: 30px;
  word-wrap: break-word;
}
.abouttext{
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  line-height: 1.56;
  color: #4a4a4a;
  left: 60px;
  padding-bottom: 30px;
  margin: 0;
}
.companytext{
  font-family: "PingFang";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.9px;
  color: #d31010;
  margin-left: 60px;
}
.companyleftinfo{
  min-width: 56px;
  font-family: "PingFang";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  text-align: left;
  color: #000000;
  margin-left:80px;
  margin-right: 33px;
  vertical-align: top;
}
.companyrightinfo{
  min-width: 200px;
  font-family: "PingFang";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  text-align: left;
  color: #4a4a4a;
  padding-left: 30px;
  padding-bottom: 15px;
  vertical-align: top;
}

.companyrightinfo ol {
  padding: 0;
}

.companyrightinfo ol li{
  margin-left: 20px;
  padding: 0;
}

hr{
  height: 0px;
  border: solid 1px #dddddd;
  margin: 0px 30px 10px 30px;
}

@media all and (max-width: 500px){
  .about_righttop{
    position: relative;
  }
  .abouttitle, .glassptoptext{
    letter-spacing: 4.5px;
    font-size: 18px;
    text-shadow: 1px 1px 1px rgba(54, 54, 54, 0.2);
    line-height: 310px;
    padding-left: 30px;
    text-align: left;
    width: 100vw;
  }
  .aboutcompany{
    position: relative;
    margin-right: 0;
    padding-bottom: 0px;

  }
  .abouttext, .companytext{
    margin: 10px auto;
    text-align: center;
    font-weight: bold;
    left: 0;
    padding: 0;
  }
  .mobile-about-content{
    width: 100vw;
    min-height: 10px;
    overflow-wrap: hidden;
    padding: 20px;
    font-family: "PingFang";
  }
  .mobile-about-content .title{
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #d31010;
    margin-bottom: 5px;
    font-weight: bold;
  }
  .mobile-about-content .content{
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #4a4a4a;
    margin-bottom: 20px;
  }
  .mobile-about-content .icon{
    width: 20px;
    height: 20px;
    filter: invert(1);
    opacity: 0.8;
  }
  .mobile-about-content .underline{
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
    padding-bottom: 2px;
    margin-bottom: 10px;
    display: inline-block;
    color: #4a4a4a;
  }
  .mobile-about-content .blod{
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: #4a4a4a;
    margin-bottom: 5px
  }
  .mobile-about-content ol{
      margin-left: -20px
  }
}
